import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary         : '#841910',
        secondary       : '#841910', //#e32112
        accent          : '#3F51B5',
        'auth-cards-bg' : '#fafafa',
        'page-bg'       : '#fcfcfc',
        'search-bg'     : '#efefef',
        'card-bg'       : '#dbf1ff',
      },
    },
  }
});
