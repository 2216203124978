import { ActionTree } from "vuex";
import { AppointmentsI } from "../interfaces/appointments.interface";
import { RootStateI } from "../interfaces/types.interface";
import { AppointmentMutations } from "./mutations";

import { marketplace_client } from '@/http/marketplace-client';
import { siti_client } from '@/http/siti-client';
import Cookies from 'js-cookie';

export const actions: ActionTree<AppointmentsI, RootStateI> = {

  async get_all_specialities({ commit }): Promise<any> {
    const res = await siti_client.get('/api/v1/speciality');
    if (!res) return;
    commit('SET_SPECIALITIES', res.data.speciality);
    return res;
  },
  
  async get_all_type_appointments({ commit }): Promise<any> {
    const res = await siti_client.get('/api/v1/TypeAppointment');
    if (!res) return res;
    commit('SET_TYPE_APPOINTMENTS', res.data);
  },

  async find_appointments({commit}, payload): Promise<any> {
    const res = await siti_client.post('/api/v1/filters/filterDoctorsWeb', payload);
    if (!res) return res;

    const results = res.data.doctors;
    const days: number[] = [];

    results.forEach((result) => {
      result.agenda            = [];
      result.current_timestamp = 0;
      result.days              = days;
      result.loading           = false;
      result.collapsed         = true;
    });

    commit('SET_APPOINTMENT_RESULTS', results);
    return results;
  },

  async find_doctor_agenda({commit}, payload): Promise<any> {
    const res = await siti_client.put(`/api/v1/filters/filterDoctorsWeb/${ payload.doctor_id }`, payload);
    if (!res) return res;
    return res.data;
  },

  async get_apointment_details({commit}, payload): Promise<any> {
    const res = await siti_client.post(`/api/v1/scheduler/list/schedulingInformation`, payload);
    if (!res) return res;
    commit(AppointmentMutations.SET_APPOINTMENT, res.data[0]);
    return res.data;
  },
  
  async get_appointment_history_by_date({commit}, date: string): Promise<any> {
    const user = JSON.parse(Cookies.get('user'));
    const payload = {
      user_id          : user.id,
      date_appointment : date
    }
    const res = await siti_client.post(`/api/v1/appointment/patients/history`, payload);
    
    if (!res) return res;
    commit(AppointmentMutations.SET_HISTORICAL, res.data);
    return res.data;
    
  },

  async schedulingTransactionInformation (_, ev_id): Promise<any> {
    const res = await siti_client.post('/api/v1/scheduler/list/schedulingTransactionInformation', {
      id_event: ev_id
    });
    if (!res) return;
    return res.data[0];
  }

}
