export default {
  path: 'compras',
  name: 'shopping',
  component: () => import('../views/shopping/index.vue'),
  children: [
    {
      path: ':id',
      name: 'shopping-detail',
      component: () => import('../views/shopping/detail.vue'),
      meta: {
        requiresAuth : true,
        title : 'Detalhe da compra',
      }
    }
  ]
}
