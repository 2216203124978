/*=========================================================================================
  Item Name: shops/state
  Item Description: state of shops
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

import { ShopsI } from "../interfaces/shops.interface";

export const state: ShopsI = {
  shops: [],
  shop_types: [],
  shop_type : {
    type_shop_id : 0,
    url_image    : 'string',
    name         : 'string',
  },
  shop: {
    id               : 0,
    name             : '',
    companies_id     : 0,
    description      : '',
    email            : '',
    status           : true,
    url              : '',
    url_image        : '',
    added_on         : '',
    address          : '',
    applications_id  : 0,
    city             : '',
    code             : '',
    cx               : 0,
    cy               : 0,
    delivery_price   : 0,
    distance         : 0,
    image            : '',
    influence        : 0,
    link             : '',
    phone            : '',
    scheduling       : {},
    score            : 0,
    type_shop_id     : 0,
    update_on        : '',
  },
  shop_categories: [],
};
