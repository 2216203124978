/*=========================================================================================
  Item Name: shops/actions
  Item Description: actions of shops
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

import { ActionTree } from "vuex";
import { ShopsI } from "../interfaces/shops.interface";
import { RootStateI } from "../interfaces/types.interface";

import { marketplace_client } from "@/http";

import { ShopsMutations } from "./mutations";

export const actions: ActionTree<ShopsI, RootStateI> = {

  async get_featured_stores ({ commit }) {
    const res = await marketplace_client.get("/api/shops_lurches");
    if (!res) return;
    commit(ShopsMutations.SET_SHOPS, res.data);
  },

  async get_all_shop_types ({ commit }, payload): Promise<any> {
    const res = await marketplace_client.post("/api/shopTypeList", payload);
    if (!res) return;
    commit(ShopsMutations.SET_SHOP_TYPES, res.data);
    return res.data;
  },

  async shops_health_plan ({commit}): Promise<void> {
    const res = await marketplace_client.get("/api/shops_health_plan");
    if (!res) return;
    commit(ShopsMutations.SET_SHOP_TYPE, res.data);
  },

  async get_shop_with_promotions ({commit}, payload): Promise<any> {
    const res = await marketplace_client.post("/api/shops_promotions", payload);
    if (!res) return;
    const promotions = res.data.promotions;
    const shops = promotions.shops_list;
    commit(ShopsMutations.SET_SHOPS, shops);
    return res.data;
  },

  async get_shops_by_type (_, payload): Promise<any> {
    const res = await marketplace_client.post("/api/shopsType", payload);
    if (!res) return;
    return res.data;
  },

  async get_shop_by_id ({commit}, id): Promise<any> {
    const res = await marketplace_client.get("/api/shops/" + id);
    if (!res) return;
    let data = res.data;
    if (data.length===0) return;
    data = data[0];
    commit(ShopsMutations.SET_SHOP, data);
    return data;
  },
  
  async get_shop_categories ({commit}, payload): Promise<any> {
    const res = await marketplace_client.post("/api/inventorycategory", payload);
    if (!res) return;
    commit(ShopsMutations.SET_SHOP_CATEGORIES, res.data);
    return res.data;
  },

  async shops_promotions_redirect(_, payload): Promise<any> {
    const res = await marketplace_client.post('/api/shops_promotions_redirect', payload);
    if (!res) return res;
    return res.data;
  }

}
