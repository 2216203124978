import { ActionTree } from "vuex";
import { CartI } from "../interfaces/cart.interface";
import { RootStateI } from "../interfaces/types.interface";

import {
  collection,
  doc,
  setDoc,
  getFirestore,
  getDoc,
  DocumentReference,
  deleteField,
  deleteDoc,
} from "firebase/firestore";
// import "firebase/firestore/";
import { getAuth } from "firebase/auth";

import Cookies from "js-cookie";
import { CartMutations } from "./mutations";
import { ProductI } from "../interfaces/products.interface";

import { marketplace_client } from '@/http'
import { marketplace_client_order } from "@/http/marketplace-client";
const db = getFirestore();

export const actions: ActionTree<CartI, RootStateI> = {
  async check_user(): Promise<any> {
    return new Promise((resolve, reject) => {
      const u_t = Cookies.get("user");
      if (!u_t) return resolve(null);
      getAuth().onAuthStateChanged((user) => {
        if (user) resolve(user);
        else resolve(null);
      });
    });
  },

  async add_to_cart({ dispatch }, payload: any) {
    try {
      const user = await dispatch("check_user");
      if (!user) {
        dispatch(
          "auth/attemp_logout",
          `/produtos/${payload.product.inv_product_id}`,
          { root: true }
        );
        return;
      }

      const shop = payload.shop;
      const product = payload.product;
      const quantity = payload.quantity;

      const products_ref = await dispatch("products_ref");
      const res_shop = await dispatch("validate_shop", { products_ref, shop });

      if (!res_shop)
        return {
          error: "El producto que está agregando es de una tienda diferente",
        };
      if (res_shop === "no shop") dispatch("add_shop", { products_ref, shop });

      await dispatch("add_product", { products_ref, product, quantity });
      dispatch("get_cart_items_count");

      return { success: true };
    } catch (error) {
      console.log(error);
      return { error: "Ha ocurrido un error al agregar el producto" };
    }
  },

  async add_pre_cart({ dispatch }, payload: any) {
    try {
      const user = await dispatch("check_user");
      if (!user) {
        dispatch(
          "auth/attemp_logout",
          `/produtos/${payload.product.inv_product_id}`,
          { root: true }
        );
        return;
      }

      const shop = payload.shop;
      const product = payload.product;
      const quantity = payload.quantity;

      const products_ref = await dispatch("products_ref");
      const res_shop = await dispatch("validate_shop", { products_ref, shop });

      if (!res_shop)
        return {
          error: "El producto que está agregando es de una tienda diferente",
        };
      if (res_shop === "no shop") dispatch("add_shop", { products_ref, shop });

      await dispatch("add_product", { products_ref, product, quantity });
      dispatch("get_cart_items_count");

      return { success: true };
    } catch (error) {
      console.log(error);
      return { error: "Ha ocurrido un error al agregar el producto" };
    }
  },

  async products_ref(): Promise<any> {
    const user = JSON.parse(Cookies.get("user"));
    // const nit = '1019081266';
    const nit = user.nit;
    const d = doc(db, `users/${nit}`);
    // get subcollection
    const subcollection = collection(d, "cart");
    const doc_ref = doc(subcollection, "products");
    const dd = await getDoc(doc_ref);
    return doc_ref;
  },

  async get_shop({ dispatch }): Promise<any> {
    const user = await dispatch("check_user");
    if (!user) {
      dispatch("auth/attemp_logout", null, { root: true });
      return;
    }

    const products_ref: DocumentReference = await dispatch("products_ref");
    const doc = await getDoc(products_ref);
    const data = doc.data();
    return data?.shop;
  },

  async get_products({ dispatch, commit }): Promise<any> {
    const user = await dispatch("check_user");
    if (!user) {
      dispatch("auth/attemp_logout", null, { root: true });
      return;
    }

    const products_ref: DocumentReference = await dispatch("products_ref");
    const doc = await getDoc(products_ref);
    const data = doc.data();
    const keys = Object.keys(data || {});

    if (keys.length === 0) return;
    delete data?.shop;
    commit(CartMutations.SET_CART_ORDER_ITEMS, data);
    return data;
  },

  async get_subtotal({ dispatch }): Promise<any> {
    const user = await dispatch("check_user");
    if (!user) {
      dispatch("auth/attemp_logout", null, { root: true });
      return;
    }

    const products_ref: DocumentReference = await dispatch("products_ref");
    const doc = await getDoc(products_ref);
    const data = doc.data();
    const keys = Object.keys(data || {});
    if (keys.length === 0) return;
    delete data?.shop;
    let subtotal = 0;
    for (const i of keys) {
      const product = data ? data[i] : null;
      if (!product) continue;
      subtotal +=
        (product?.discount ? product?.discount : product?.price_net) *
        product?.quantity;
    }

    return subtotal;
  },

  async empty_cart({ dispatch, commit }): Promise<any> {
    const user = await dispatch("check_user");
    if (!user) {
      dispatch("auth/attemp_logout", null, { root: true });
      return;
    }

    const products_ref: DocumentReference = await dispatch("products_ref");
    await deleteDoc(products_ref);
    commit(CartMutations.SET_CART_ITEMS, 0);
  },

  async get_cart_items_count({ dispatch, commit }): Promise<any> {
    const user = await dispatch("check_user");
    if (!user) {
      dispatch("auth/attemp_logout", null, { root: true });
      return;
    }

    const products_ref: DocumentReference = await dispatch("products_ref");
    const doc = await getDoc(products_ref);
    const data = doc.data();
    if (!data) return;

    let num_items = 0;

    for (const key of Object.keys(data || {})) {
      if (key === "shop") continue;

      const product = data[key];
      num_items += product.quantity;
    }

    commit(CartMutations.SET_CART_ITEMS, num_items);
  },

  async validate_shop(_, payload): Promise<any> {
    const doc_ref: DocumentReference = await payload.products_ref;
    const doc = await getDoc(doc_ref);
    const data = doc.data();
    const shop = data?.shop;

    if (!shop) return "no shop";
    if (shop.shop_id == payload.shop.id) return "same shop";

    return false;
  },

  async add_shop(_, payload): Promise<any> {
    const doc_ref: DocumentReference = await payload.products_ref;
    const shop = {
      ...payload.shop,
      shop_id: payload.shop.id,
    };
    setDoc(doc_ref, { shop }, { merge: false });
  },

  async add_product({ dispatch }, payload): Promise<any> {
    const doc_ref: DocumentReference = await payload.products_ref;
    const doc = await getDoc(doc_ref);
    const data = doc.data();
    const product = payload.product;
    const quantity = payload.quantity;
    let p_id = product.product_id;
    if (!product.product_id) {
      p_id = product.id;
    }
    const p = data?.[p_id];

    let d;

    if (!p) {
      d = {
        ...product,
        quantity,
      };
    } else {
      d = {
        ...p,
        quantity: p.quantity + quantity,
      };
    }

    await setDoc(doc_ref, { [p_id]: d }, { merge: true });
    // if product quantity is 0, delete product
    if (d){
      if (d.quantity <= 0) {
        await setDoc(doc_ref, { [p_id]: deleteField() }, { merge: true });
      }
    }

    await dispatch("check_num_items", doc_ref);
    await dispatch("get_products");
  },

  async get_product({ dispatch }, p_id): Promise<any> {
    const user = await dispatch("check_user");
    if (!user) {
      // dispatch('auth/attemp_logout', null, { root: true });
      return;
    }

    try {
      const products_ref = await dispatch("products_ref");
      const doc_ref: DocumentReference = products_ref;
      const doc = await getDoc(doc_ref);
      const data = doc.data();
      const product = data?.[p_id];
      return product;
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  async check_num_items(_, doc_ref: DocumentReference): Promise<void> {
    const data = await getDoc(doc_ref);
    const num_items = Object.keys(data.data() || {}).length;
    if (num_items === 1) {
      await setDoc(doc_ref, { shop: deleteField() }, { merge: true });
    }
  },

  async process_purhcase({ dispatch }, payload): Promise<any> {
    const doc_ref = await dispatch("get_main_cart");
    const doc = await getDoc(doc_ref);
    const data = doc.data();
    const d = {
      doc_ref,
    };

    await dispatch("set_data_to_main_cart", d);
  },

  async set_data_to_main_cart({ dispatch }, payload): Promise<any> {
    const u = JSON.parse(Cookies.get("user"));
    const nit = u.nit;
    const user_id = u.id;
    const email = u.email;

    const doc_ref: DocumentReference = payload.doc_ref;
    let shop = await dispatch("get_shop");
    if (!shop) {
      shop = payload.shop;
    }
    // TODO re-evaluate products
    let prods = await dispatch("get_products");
    if (!prods) {
      prods = payload.products;
    }
    const products: ProductI[] = [];

    for (const i of Object.keys(prods || {})) {
      const product = prods[i];
      products.push(product);
    }

    // cart id is a random string and number
    const cart_id =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    const selected_address = JSON.parse(
      localStorage.getItem("selected_address") || "{}"
    );
    const method = JSON.parse(localStorage.getItem("method") || "{}");
    const data = {
      delivery_address: selected_address,
      cart_items: products,
      shop_id: shop.id,
      content: "",
      cart_id,
      payment_type: method,
    };

    await setDoc(
      doc_ref,
      {
        user_id: user_id,
        user_email: email,
        user_identification: nit,
        cart_progress: [data],
      },
      { merge: true }
    );
  },

  async change_payment({ dispatch }, payload): Promise<any> {
    const u = JSON.parse(Cookies.get("user"));
    const nit = u.nit;
    const user_id = u.id;
    const email = u.email;

    const doc_ref = await dispatch("get_main_cart");
    const shop = await dispatch("get_shop");
    // TODO re-evaluate products
    const prods = await dispatch("get_products");
    const products: ProductI[] = [];

    for (const i of Object.keys(prods || {})) {
      const product = prods[i];
      products.push(product);
    }

    // cart id is a random string and number
    const cart_id =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    const selected_address = JSON.parse(
      localStorage.getItem("selected_address") || "{}"
    );
    const method = JSON.parse(localStorage.getItem("method") || "{}");
    const data = {
      delivery_address: selected_address,
      cart_items: products,
      shop_id: shop.id,
      content: "",
      cart_id,
      payment_type: method,
    };

    await setDoc(
      doc_ref,
      {
        user_id: user_id,
        user_email: email,
        user_identification: nit,
        cart_progress: [data],
      },
      { merge: true }
    );
  },

  async get_main_cart({ dispatch }): Promise<DocumentReference | undefined> {
    const user = await dispatch("check_user");
    if (!user) {
      dispatch("auth/attemp_logout", null, { root: true });
      return;
    }
    const u = JSON.parse(Cookies.get("user"));
    const nit = u.nit;
    const doc_ref = doc(db, `serviroutes_carts/${nit}`);
    return doc_ref;
  },

  async get_order({ dispatch, commit }): Promise<any> {
    const user = await dispatch("check_user");
    if (!user) {
      dispatch("auth/attemp_logout", null, { root: true });
      return;
    }

    const main_cart: DocumentReference = await dispatch("get_main_cart");
    const doc = await getDoc(main_cart);
    const data = doc.data();
    const cart_progress = data?.cart_progress;
    const cart_id = cart_progress?.[0]?.cart_id;
    const u = JSON.parse(Cookies.get("user"));
    const key = u.nit;
    const payload = { key, cart_id };
    const res = await marketplace_client_order.post('/api/orders', payload);
    if (!res) return;
    const { order_items, products_bonuses } = res.data;
    commit(CartMutations.SET_PRODUCTS_BONUSES, products_bonuses);
    commit(CartMutations.SET_ORDER_ITEMS, order_items);
    return res.data;
  },
};
