import { DocumentTypeI, LanguageI, RootStateI, SnackI } from "./interfaces/types.interface";

export enum AppMutations {
  SET_QUERY                = "SET_QUERY",
  SET_LANG                 = "SET_LANG",
  SET_SNACK                = "SET_SNACK",
  SET_DOC_TYPES            = "SET_DOC_TYPES",
  SET_SELECTED_ADDRESS     = "SET_SELECTED_ADDRESS",
  SET_TERMS_AND_CONDITIONS = "SET_TERMS_AND_CONDITIONS",
  SET_POLICY_PRIVACY = "SET_POLICY_PRIVACY"
}

export const mutations = {
  [AppMutations.SET_QUERY](state: RootStateI, query: string): void { state.query = query; },
  [AppMutations.SET_LANG](state: RootStateI, lang: LanguageI): void { state.language = lang; },
  [AppMutations.SET_SNACK](state: RootStateI, snack: SnackI): void {
    state.snackbar = {
      ...snack,
      show : true,
    }
  },
  [AppMutations.SET_DOC_TYPES](state: RootStateI, docTypes: DocumentTypeI[]): void {
    state.document_types = docTypes;
  },
  [AppMutations.SET_SELECTED_ADDRESS](state: RootStateI, address): void {
    const payload = {
      ...address,
      city : address.province,
      cx   : address.longitude,
      cy   : address.latitude
    }
    localStorage.setItem("selected_address", JSON.stringify(payload));
    state.selected_address = payload;
  },
  [AppMutations.SET_TERMS_AND_CONDITIONS](state: RootStateI, termsAndConditions): void {
    state.terms_and_conditions = termsAndConditions;
  },
  [AppMutations.SET_POLICY_PRIVACY](state: RootStateI, policy_privacy): void {
    state.policy_privacy = policy_privacy;
  }
}
