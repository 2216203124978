import Vue from "vue";

// capitalize text filter
Vue.filter("capitalize", function(value: string) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});
// local price filter
Vue.filter("price", function(value: number) {
  if (!value) return "";
  const res = value.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
    // zeros after decimal
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
    // remove currency symbol
  });
  // if BRL in res remove
  return res.replace("BRL", "");
});
// local camelize filter
Vue.filter("camelize", function(str: string) {
  return str.split(' ').map(function(word,index){
    // If it is the first word make sure to lowercase all the chars.
    //if(index == 0){
    //  return word.toLowerCase();
    //}
    // If it is not the first word only upper case the first char and lowercase the rest.
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }).join(' ');
})

Vue.filter("sku", function(str: string) {
  return str.split('_')[1]
})
// cellphone filter
Vue.filter("cellphone", function(value: string) {
  if (!value) return "";
  value = value.replace('-', ' ');
  return value.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
});
// date filter
Vue.filter("date", function(value: number, format='yyyy-MM-dd', tojs=false) {
  if (!value) return "";  

  const date = new Date(tojs ? value * 1000 : value);
  const es_months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const es_days = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];
  
  let result = '';
  
  switch(format) {
    case 'MM': {
      result = es_months[date.getMonth()];
      break;
    }

    case 'dd': {
      result = date.getDate().toString();
      break;
    }

    case 'DD': {
      result = es_days[date.getDay()];
      break;
    }
    
    case 'yyyy': {
      result = date.getFullYear().toString();
      break;
    }

    case 'yyyy-MM-dd': {
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      result = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
      break;
    }

    case 'yyyy-MM-dd HH:mm:ss': {
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();
      result = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day} ${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
      break;
    }

    default: {
      result = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
      break;
    }

  }
  return result; 
});
