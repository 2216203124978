import { AppointmentsI } from "../interfaces/appointments.interface";

export const state: AppointmentsI = {
  appointments        : [],
  specialities        : [],
  appointment_types   : [],
  appointment_results : [],
  appointment         : {
    id: "",
    speciality_id: "",
    type: "",
    city_dane: "",
    price_range: [30000, 500000],
    init_time: "",
    end_time: "",
    date: "",
    payment_method: "",
  },
  scheduled_appointments: [],
  accomplished_appointments: [],
}
