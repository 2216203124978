export default {
  path: 'carrinho',
  name: 'cart-index',
  component: () => import('../views/cart/index.vue'),
  children: [
    {
      path: '',
      name: 'cart',
      component: () => import('../views/cart/cart.vue'),
      meta: {
        requiresAuth: true,
        title: 'Carrinho de compras'
      }
    }
  ]
}
