export default {
  path : 'categorias',
  name : 'categories',
  component: () => import('../views/categories/index.vue'),
  children : [
    {
      path : '',
      name : 'categories-list',
      component: () => import('../views/categories/list.vue'),
      meta: {
        requiresAuth: false,
        title: 'Lista de Categorias'
      }
    },
    {
      path : ':id',
      name : 'categories-detail',
      component: () => import('../views/categories/detail.vue'),
      meta: {
        requiresAuth: false,
        title: 'Detalhes da categoria'
      }
    }
  ]
};