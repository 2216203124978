/*=========================================================================================
  Item Name: products/mutations
  Item Description: mutations of products
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

import { MutationTree } from "vuex";

import { SearchI } from "../interfaces/search.interface";

export enum SearchMutations {
}

export const mutations: MutationTree<SearchI> = {
}
