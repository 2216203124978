/*=========================================================================================
  Item Name: products/actions
  Item Description: actions of products
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

import { ActionTree } from "vuex";
import { ProductsI } from "../interfaces/products.interface";
import { RootStateI } from "../interfaces/types.interface";

import { marketplace_client } from "@/http";

import { ProductsMutations } from "./mutations";

export const actions: ActionTree<ProductsI, RootStateI> = {

  async get_product (_, id): Promise<any> {
   const res = await marketplace_client.get(`/api/products/${ id }`);
   if (!res) return;
   return res.data;
  },

  async get_product_from_inventory (_, id): Promise<any> {
    const res = await marketplace_client.get(`/api/inventorycategory/${ id }`);
    if (!res) return;
    return res.data;
  },
  
  async get_favorite_products ({ commit }) {
    const res = await marketplace_client.get("/api/product_favorites");
    if (!res) return;
    commit(ProductsMutations.SET_PRODUCTS, res.data);
  },

  async product_favorites_filter (_, payload): Promise<any> {
    const res = await marketplace_client.post('/api/product_favorites_filter', payload);
    if (!res) return;
    return res.data;
  },
  async pre_orders (_, payload): Promise<any>{
    const res = await marketplace_client.get('/api/product_group_productUsers');
    if (!res) return;
    return res.data;
  },
  async send_lead (_, payload): Promise<any> {
    const res = await marketplace_client.post('/api/leads', payload);
    if (!res) return;
    return res.data;
  }

}
