import { MutationTree } from "vuex";
// interfaces
import { UserI, UsersI } from "../interfaces/users.interface";

export enum UserMutations {
  SET_USER = "SET_USER",
  SET_USER_CELLPHONE = "SET_USER_CELLPHONE",
  SET_REGISTRING = "SET_REGISTRING",
}

export const mutations: MutationTree<UsersI> = {
  
  [UserMutations.SET_USER](state: UsersI, user: UserI): void {
    state.user = user;
  },

  [UserMutations.SET_USER_CELLPHONE](state: UsersI, cellPhone: string): void {
    state.user.cellphone = cellPhone;
  },

  [UserMutations.SET_REGISTRING](state: UsersI, registring: boolean): void {
    state.user.registring = registring;
  },
}
