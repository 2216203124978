/*=========================================================================================
  Item Name: products/actions
  Item Description: actions of products
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

import { ActionTree } from "vuex";

import { RootStateI } from "../interfaces/types.interface";
import { SearchI }    from "../interfaces/search.interface";

import { marketplace_client } from "@/http";

export const actions: ActionTree<SearchI, RootStateI> = {

  async searchall (_, payload): Promise<any> {
    const res = await marketplace_client.post('/api/searchall', payload);
    if (!res) return;
    return res.data;
  }

}
