import { ActionTree } from "vuex";
// interfaces
import { RootStateI } from "../interfaces/types.interface";
import { PaymentsI } from "../interfaces/payment.interfase";

import { siti_client } from "@/http/siti-client";
import { marketplace_client, marketplace_client_payment, marketplace_client_order } from "@/http/marketplace-client";

export const actions: ActionTree<PaymentsI, RootStateI> = {

  async get_all_payment_methods({ commit }): Promise<any> {
    const res = await marketplace_client.get('/api/payments/method');
    if (!res) return;
    commit('SET_PAYMENT_METHODS', res.data);
    return res.data; 
  },

  async get_all_registered_cards({ commit }): Promise<any> {
    const res = await marketplace_client.get('/api/payments/registeredUserCardList');
    if (!res) return;
    const copy = JSON.parse(JSON.stringify(res.data));
    const select = JSON.stringify(copy[0])
    
    localStorage.setItem('method', select)
    
    commit('SET_CARDS', res.data);
    return copy;
  },
    
  async save_card({dispatch}, card): Promise<any> {
    const res = await marketplace_client.post('/api/payments/cardRegistration', card);
    if (!res) return;
    await dispatch('get_all_registered_cards');
    return res;
  },

  async get_all_financial_institutions({ commit }): Promise<any> {
    const res = await marketplace_client.get('/api/payments/financialInstitutions');
    if (!res) return;
    commit('SET_FINANCIAL_INSTITUTIONS', res.data);
    return res.data;
  },

  async pay_appointment(_, appointment): Promise<any> {
    const res = await siti_client.post('/api/v1/appointment/create/paymentWompi', appointment);
    if (!res) return;
    return res;
  },

  async delete_card({dispatch}, card_id): Promise<any> {    
    const res = await marketplace_client.delete(`/api/payments/deleteCardRegistration/${card_id}`);  
    if (!res) return;
    await dispatch('get_all_registered_cards');
    return res;
  },

  async pay (_, payment): Promise<any> {
    const res = await marketplace_client_order.post('/api/payments/transactions', payment);
    if (!res) return;
    return res;
  }
}
