export default {
  path: 'pagar',
  name: 'payment-index',
  component: () => import('../views/payments/index.vue'),
  children: [
    {
      path: '',
      name: 'payment',
      component: () => import('../views/payments/payment.vue'),
      meta: {
        requiresAuth: true,
        title: 'Ordem de pagamento'
      }
    },
    {
      path: 'confirmação/:id',
      name: 'payment-confirmation',
      component: () => import('../views/payments/confirmation.vue'),
      meta: {
        requiresAuth: true,
        title: 'Resumo do pagamento'
      }
    }
  ]
}
