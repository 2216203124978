import { UsersI } from "../interfaces/users.interface";

export const state: UsersI = {
  users: [],
  user: {
    id              : 0,
    cellphone       : "",
    username        : "",
    names           : "",
    lastname        : "",
    registring      : false,
    gender          : 'man',
    document_type   : 'CC',
    document_number : '',
    password        : '',
    address         : '',
    address_data    : '',
    type_login      : 'email',
  },
}
