import { MutationTree } from "vuex";
// interfaces
import { PaymentsI } from "../interfaces/payment.interfase";

export enum PaymentMutations {
  SET_CARDS                  = 'SET_CARDS',
  SET_CARD                   = 'SET_CARD',
  SET_PAYMENT_METHODS        = 'SET_PAYMENT_METHODS',
  SET_FINANCIAL_INSTITUTIONS = 'SET_FINANCIAL_INSTITUTIONS',
}

export const mutations: MutationTree<PaymentsI> = {
  [PaymentMutations.SET_CARDS](state, cards) {
    const index_card = cards.findIndex(card => card.payment_name === 'CARD' && !card.brand);
    if (index_card >= 0) delete cards[index_card];

    const cs: any[] = [];
    cards.forEach(card => {
      if (card.id) cs.push(card);
    });

    state.cards = cs;
  },
  [PaymentMutations.SET_CARD](state, card) {
    state.card = card;
  },
  [PaymentMutations.SET_PAYMENT_METHODS](state, payment_methods) {
    state.payment_methods = payment_methods;
  },
  [PaymentMutations.SET_FINANCIAL_INSTITUTIONS](state, financial_institutions) {
    state.financial_institutions = financial_institutions;
  }
}
