/*=========================================================================================
  Item Name: shops/mutations
  Item Description: mutations of shops
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

import { MutationTree } from "vuex";

import { ShopsI } from "../interfaces/shops.interface";

export enum ShopsMutations {
  SET_SHOPS           = "SET_SHOPS",
  SET_SHOP_TYPES      = "SET_SHOP_TYPES",
  SET_SHOP_TYPE       = "SET_SHOP_TYPE",
  SET_SHOP            = "SET_SHOP",
  SET_SHOP_CATEGORIES = "SET_SHOP_CATEGORIES"
}

export const mutations: MutationTree<ShopsI> = {
  [ShopsMutations.SET_SHOPS](state, shops) {
    state.shops = shops;
  },
  [ShopsMutations.SET_SHOP_TYPES](state, shop_types) {
    state.shop_types = shop_types;
  },
  [ShopsMutations.SET_SHOP_TYPE](state, shop_type) {
    state.shop_type = shop_type;
  },
  [ShopsMutations.SET_SHOP](state, shop) {
    state.shop = shop;
  },
  [ShopsMutations.SET_SHOP_CATEGORIES](state, shop_categories) {
    state.shop_categories = shop_categories;
  }
}
