export default {
  path: 'produtos',
  name: 'products',
  component: () => import('@/views/products/index.vue'),
  meta: {
    title: 'Produtos',
    requiresAuth: true,
  },
  children: [
    {
      path: ':id',
      name: 'product-detail',
      component: () => import('@/views/products/product.vue'),
      meta: {
        title: 'Detalhes do produto',
        requiresAuth: false,
      },
    }
  ]
}
