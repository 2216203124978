import { Module } from "vuex";
import { AppointmentsI } from "../interfaces/appointments.interface";
// interfaces
import { RootStateI } from "../interfaces/types.interface";
// store moudules
import { actions }   from "./actions";
import { getters }   from "./getters";
import { mutations } from "./mutations";
import { state }     from "./state";

export const appointments: Module<AppointmentsI, RootStateI> = {
  actions,
  getters,
  mutations,
  state,
  namespaced: true,
}