import { PaymentsI } from "../interfaces/payment.interfase";

export const state: PaymentsI = {
  cards : [],
  card : {
    id        : 0,
    name      : '',
    type      : 'credit',
    franchise : '',
    image     : '',
    number    : '',
    cvv       : '',
    expiry    : {
      month : '',
      year  : '',
    },
  },
  payment_methods : [],
  financial_institutions : [],
}
