import { siti_client, marketplace_client } from '@/http'

export const actions = {
  async get_all_document_types({ commit }) {
    const res = await siti_client.get('/api/v1/typesDocument');
    if (!res) return;
    commit('SET_DOC_TYPES', res.data);
    return res.data;
  },

  async get_terms_and_conditions({ commit }, id: string) {
    const res = await marketplace_client.get(`/api/termsAndConditions/${id}`);
    if (!res) return;
    if (id == '2'){
      commit('SET_POLICY_PRIVACY', res.data.description);
    } else {
      commit('SET_TERMS_AND_CONDITIONS', res.data.description);
    }
    
    return res.data;
  },

  async get_privacy_policy (): Promise<any> {
    const res = await siti_client.get('/api/v1/privacypolicies');
    if (!res) return;
    return res.data;
  }
};
