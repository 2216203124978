/*=========================================================================================
  Item Name: products/mutations
  Item Description: mutations of products
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

import { MutationTree } from "vuex";

import { ProductsI } from "../interfaces/products.interface";

export enum ProductsMutations {
  SET_PRODUCTS = "SET_PRODUCTS",
}

export const mutations: MutationTree<ProductsI> = {
  [ProductsMutations.SET_PRODUCTS](state, products) {
    state.products = products;
  },
}
