import { ActionTree } from "vuex";
// interfaces
import { RootStateI } from "../interfaces/types.interface";
import { LocationI } from "../interfaces/location.interface";
// mutations
import { LocationMutations } from "./mutations";

// http client
import { siti_client as client, marketplace_client } from '@/http';

export const actions: ActionTree<LocationI, RootStateI> = {
  
  async get_all_cities({commit}) {
    const res = await client.get('/api/v1/citiesInfo');
    if( !res ) return null;
    commit(LocationMutations.SET_CITIES, res.data);
    return res;
  },

  async get_all_departments({commit}) {
    const res = await client.get('/api/v1/departament');
    if( !res ) return null;
    commit(LocationMutations.SET_DEPARTMENTS, res.data);
    return res;
  },

  async get_cities_by_department({commit}, department_id) {
    if (department_id){
      const res = await client.get('/api/v1/cities/' + department_id);
      if( !res ) return null;
      commit(LocationMutations.SET_CITIES, res.data);
      return res;
    }
  },

  async get_coords_by_address(_, payload): Promise<any> {
    const res = await client.post('/api/v1/geocoder', payload);
    if( !res ) return {cx: 0, cy: 0};
    return res.data;
  },

  async get_all_user_address_list({commit}) {
    const res = await marketplace_client.get('/api/UserAddress');
    if( !res ) return null;
    commit(LocationMutations.SET_ADDRESS_LIST, res.data);
    return res;
  },

  async save_address({dispatch}, payload) {
    const res = await marketplace_client.post('/api/UserAddress', payload);
    if( !res ) return null;
    await dispatch('get_all_user_address_list');
    return res;
  },

  async delete_address({dispatch}, address) {    
    const res = await marketplace_client.delete('/api/UserAddress/' + address.id);
    if( !res ) return null;
    await dispatch('get_all_user_address_list');
    return res;
  },

  async get_address_from_coords(_, payload) {
    payload.geo = "INVERSO";
    const res = await marketplace_client.post('/api/geo', payload);
    if( !res ) return null;
    return res.data;
  }

}
