import { MutationTree } from "vuex";
import { AppointmentsI, HistoricalAppointmentI } from "../interfaces/appointments.interface";

export enum AppointmentMutations {
  SET_APPOINTMENT         = "SET_APPOINTMENT",
  SET_SPECIALITIES        = "SET_SPECIALITIES",
  SET_TYPE_APPOINTMENTS   = "SET_TYPE_APPOINTMENTS",
  SET_APPOINTMENT_RESULTS = "SET_APPOINTMENT_RESULTS",
  RESET_APPOINTMENT       = "RESET_APPOINTMENT",
  SET_HISTORICAL          = "SET_HISTORICAL",
}

export const mutations: MutationTree<AppointmentsI> = {
  [AppointmentMutations.SET_APPOINTMENT](state, appointment) {
    state.appointment = appointment;
  },
  [AppointmentMutations.RESET_APPOINTMENT](state) {
    state.appointment = {
      id: "",
      speciality_id: "",
      type: "",
      city_dane: "",
      price_range: [30000, 500000],
      init_time: "",
      end_time: "",
      date: "",
      payment_method: "",
    };
  },
  [AppointmentMutations.SET_SPECIALITIES](state, specialities) {
    state.specialities = specialities;
  },
  [AppointmentMutations.SET_TYPE_APPOINTMENTS](state, appointment_types) {
    state.appointment_types = appointment_types;
  },
  [AppointmentMutations.SET_APPOINTMENT_RESULTS](state, appointment_results) {
    state.appointment_results = appointment_results;
  },
  [AppointmentMutations.SET_HISTORICAL](state, appointments: HistoricalAppointmentI[]) {
    state.scheduled_appointments = [];
    state.accomplished_appointments = [];

    appointments = appointments.map((appointment) => {
      return { ...appointment, collapsed: true };
    });

    const current_ts = new Date().getTime();
    // get appointments date and get timestamp
    const appointments_date = appointments.map((appointment, index) => {
      return { date: appointment.date_appointment, index};
    });
    // convert string dates to timestamp
    const appointments_date_timestamp = appointments_date.map((appointment_date) => {
      return { date: new Date(appointment_date.date).getTime(), index: appointment_date.index };
    });
    for (const ap of appointments_date_timestamp) {
      if (ap.date > current_ts)
        state.accomplished_appointments.push(appointments[ap.index]);
      else
        state.scheduled_appointments.push(appointments[ap.index]);
    }
  }
};
