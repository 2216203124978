import { MutationTree } from "vuex";
import Cookies          from "js-cookie";
// interfaces
import { AuthI, SessionI, UserI } from "../interfaces/auth.interface";

export enum AuthMutations {
  SET_SESSION      = 'SET_SESSION',
  SET_USER         = 'SET_USER',
  REMOVE_SESSION   = 'REMOVE_SESSION',
  SET_ADDRESS_LIST = 'SET_ADDRESS_LIST',
}

export const mutations: MutationTree<AuthI> = {
  
  [AuthMutations.SET_SESSION](state, session: any) {
    state.session.user          = session['InfoUser'];
    state.session.authorization = session['Authorization'];
    if( session.persistent ) {
      Cookies.set('token', session.authorization.access_token);
      Cookies.set('user', JSON.stringify(state.session.user));
      sessionStorage.setItem('token', session.authorization.access_token);
    } else {
      sessionStorage.setItem('token', session.authorization.access_token);
      sessionStorage.setItem('user', JSON.stringify(state.session.user));
    }

    sessionStorage.removeItem('temporary_token');
  },

  [AuthMutations.SET_USER](state, user: UserI) {
    state.session.user = user;

    const is_persistent = Cookies.get('token');
    
    if( is_persistent ) {
      Cookies.remove('user');
      Cookies.set('user', JSON.stringify(user));

    } else {
      sessionStorage.removeItem('user');
      sessionStorage.setItem('user', JSON.stringify(user));
    }
  },

  [AuthMutations.REMOVE_SESSION]() {
    Cookies.remove('token');
    Cookies.remove('user');

    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');

    localStorage.clear();
  },

  [AuthMutations.SET_ADDRESS_LIST](state, address_list) {
    state.session.user.address_list = address_list;
  }
}
