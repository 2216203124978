import { ActionTree } from "vuex";
import {
  GoogleAuthProvider,
  signInWithPopup,
  getAuth,
  OAuthProvider,
  FacebookAuthProvider,
  signInWithEmailAndPassword,
  sendPasswordResetEmail
} from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from "firebase/messaging";
import { doc, getFirestore, getDoc, setDoc } from "firebase/firestore";
// interfaces
import { RootStateI } from "../interfaces/types.interface";
import { AuthI } from "../interfaces/auth.interface";
// mutations
import { AuthMutations } from "./mutations";
// http client
import { marketplace_client, siti_client as client, siti_client } from '@/http';
import { marketplace_client_login } from '@/http/marketplace-client'
import { LocationMutations } from "../location/mutations";

export const actions: ActionTree<AuthI, RootStateI> = {

  async attemp_login({dispatch}, payload: any) {
    payload = {
      ...payload,
      client_secret: process.env.VUE_APP_CLIENT_SECRET,
    };

    switch (payload.type_login) {
      case 'email':
        return dispatch('login_with_email', payload);
      
      case 'cellphone':
        return dispatch('login_with_cellphone', payload);
      
      case 'google':
        return dispatch('login_with_google', payload);

      case 'apple':
        return dispatch('login_with_apple', payload);

      case 'facebook':
        return dispatch('login_with_facebook', payload);

      default:
        console.error('login type not found');
        return;
    }
  },

  change_location({dispatch}, payload: any){
    return dispatch('login_with_location', payload);
  },

  async login_with_location ({commit, dispatch}, payload: any) {
    const res = await marketplace_client.get(`/api/auth/loginClientDuplicateSakura/${payload.identification}`);
    
    console.log("🚀 ~ file: actions.ts:61 ~ login_with_location ~ res:", res)
    if( !res ) return null;
    const request = {
      ...res.data,
      persistent : payload.login_persistent,
    }
    const obj_fb = {
      email    : request['InfoUser']['email'],
      password : request['authorization']['pass_firebase'],
      nit      : request['InfoUser']['nit']
    }
    
    const res_fb = await dispatch('login_with_firebase', obj_fb);
    if (!res_fb) return;
    commit(AuthMutations.SET_SESSION, request);
    commit(AuthMutations.SET_USER, request['InfoUser'])

    const params = window.location.search;
    setTimeout(() => {
      window.location.href = `/${ params }`;
    }, 1000);
    return res;
    
  },

  async login_with_email ({commit, dispatch}, payload: any) {
    payload = {
      ...payload,
      client_secret: process.env.VUE_APP_CLIENT_SECRET,
      type_login: 'userpass'
    }
    const res = await marketplace_client_login.post('/api/auth/loginClientsSakura', payload);
    
    if( !res ) return null;
    if (res.data.status == 400){
      return res;
    } else {
      const request = {
        ...res.data,
        persistent : payload.login_persistent,
      }
      const obj_fb = {
        email    : request['InfoUser']['email'],
        password : request['authorization']['pass_firebase'],
        nit      : request['InfoUser']['nit']
      }
      
      const res_fb = await dispatch('login_with_firebase', obj_fb);
      if (!res_fb) return;
      commit(AuthMutations.SET_SESSION, request);
      await dispatch('get_all_user_address_list')

      const params = window.location.search;
      setTimeout(() => {
        window.location.href = `/${ params }`;
      }, 1000);
      return res;
    }
    
  },

  async get_all_user_address_list({commit, dispatch}) {
    const res = await marketplace_client.get('/api/UserAddress');
    console.log("🚀 ~ file: actions.ts:127 ~ get_all_user_address_list ~ res:", res)
    if( !res ) return null;
    commit(LocationMutations.SET_ADDRESS_LIST, res.data);
    await dispatch('change_location', res.data[0]);
    return res;
  },

  async login_with_firebase ({dispatch}, payload: any) {
    try {
      const user_pass = payload.password.split('#');
      const email = `${ user_pass[0] }@servinformacion.com`;
      const password = user_pass[1];
      const auth = getAuth();
      const user = await signInWithEmailAndPassword(auth, email, password);
      localStorage.removeItem('selected_address');
      await dispatch('get_fcm_token', payload.nit);
      return user;
      
    } catch (error: any) {
      console.error('error code', error.code);
      console.error('error message', error.message);
      return;
    }
  },

  async get_fcm_token ({dispatch}, nit): Promise<void> {
    try {
      const messaging = getMessaging();
      const token = await getToken(messaging, { vapidKey: process.env.VUE_APP_P_API_KEY });
      await dispatch('set_fcm_token', { token, nit });

    } catch (err) {
      console.warn('An error ocurred while retrieving token. ', err);
      
    }
  },

  async set_fcm_token (_, payload): Promise<void> {
    const db = getFirestore();
    const nit = payload.nit;
    const ref = doc(db, `users/${nit}`);
    await setDoc(
      ref,
      { fcm: payload.token },
      {merge: true}
    );

  },

  async login_with_cellphone (_, payload: any) {
    payload = {
      ...payload,
      client_secret: process.env.VUE_APP_CLIENT_SECRET,
      persistent : true,
    }
    const res = await client.post('/api/auth/loginClientsSakura', payload);
    
    if( !res ) return null;
    
    return res;
    
    
  },

  async login_with_sitidoctor ({dispatch, commit}, payload: any) {
    const res = await client.post('/api/auth/loginClientsSakura', payload);
    if( !res ) return null;
    const request = {
      ...res.data,
      persistent : true,
    };
    const obj_fb = {
      email    : request['InfoUser']['email'],
      password : request['Authorization']['pass_firebase'],
      nit      : request['InfoUser']['nit']
    }
    const res_fb = await dispatch('login_with_firebase', obj_fb);
    if (!res_fb) return;
    commit(AuthMutations.SET_SESSION, request);
    setTimeout(() => {
      window.location.href = '/';
    }, 1000);
    return res;
  },

  async login_with_google ({ dispatch }, payload: any): Promise<any> {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    try {
      const res = await signInWithPopup(auth, provider);
      const user = res.user;
      payload = {
        ...payload,
        uid        : user.uid,
        username   : user.email,
        names      : user.displayName,
        type_login : 'social',
      }
      return dispatch('login_with_sitidoctor', payload);

    } catch (err: any) {
      console.log(err);
      
      console.error('auth/login_with_google ERROR', err.message);
      return;
    }
    
    
  },

  async login_with_facebook ({ dispatch }, payload: any) {
    const auth = getAuth();
    const provider = new FacebookAuthProvider();

    try{
      const res = await signInWithPopup(auth, provider);
      const user = res.user;
      payload = {
        ...payload,
        uid        : user.uid,
        username   : user.email,
        names      : user.displayName,
        type_login : 'social',
      }
      return dispatch('login_with_sitidoctor', payload);

    } catch(err: any) {
      console.error('auth/login_with_facebook ERROR', err.message);
      return;
    }

  },

  async login_with_apple({ dispatch }, payload: any) {
    const provider = new OAuthProvider('apple.com');
    const auth = getAuth();

    provider.addScope('email');
    provider.addScope('name');    

    return signInWithPopup(auth, provider)
      .then((result) => { 
        // The signed-in user info.
        const user = result.user;

        // Apple credential
        const credential = OAuthProvider.credentialFromResult(result);
        const accessToken = credential?.accessToken;
        const idToken = credential?.idToken;  
             
        // const user = res.user;
        // payload = {
        //   ...payload,
        //   uid        : user.uid,
        //   username   : user.email,
        //   names      : user.displayName,
        //   type_login : 'social',
        // }
        // return dispatch('login_with_sitidoctor', payload);
      }).catch((err) => {
        console.log(err.code);
        console.log(err.message);
        console.log(err.email);
        
        console.error('auth/login_with_apple ERROR', err.message);
        return;
      });
    
  },

  async register ({dispatch,commit}, payload): Promise<any> {
    payload = {
      ...payload,
      client_secret: process.env.VUE_APP_CLIENT_SECRET,
    }
    const res = await client.post('/api/v1/registerPacients', payload);
    if (!res) return;
    if (!res.data?.InfoUser) return res;
    
    const request = {
      ...res.data,
      persistent : true,
    };
    const obj_fb = {
      email    : request['InfoUser']['email'],
      password : request['Authorization']['pass_firebase'],
      nit      : request['InfoUser']['nit']
    }
    const res_fb = await dispatch('login_with_firebase', obj_fb);
    if (!res_fb) return;
    commit(AuthMutations.SET_SESSION, request);
    setTimeout(() => {
      window.location.href = '/';
    }, 1000);
    localStorage.removeItem('selected_address');
    return res;
  },

  async verify_code({dispatch, commit}, code: string) {
    const res = await client.get(`/api/auth/validateCode/${ code }`);
    if( !res ) return null;

    const request = {
      ...res.data,
      persistent : true,
    };
    const obj_fb = {
      email    : request['InfoUser']['email'],
      password : request['Authorization']['pass_firebase'],
      nit      : request['InfoUser']['nit']
    }
    const res_fb = await dispatch('login_with_firebase', obj_fb);
    if (!res_fb) return;
    commit(AuthMutations.SET_SESSION, request);
    setTimeout(() => {
      window.location.href = '/';
    }, 1000);
    return res;
  },

  async update_user(_, payload) {
    const res = await client.put('/api/v1/patient', payload);
    if( !res ) return null;
    return res;
  },

  async login_anonymously(): Promise<void> {
    const payload = {
      client_secret : process.env.VUE_APP_CLIENT_SECRET,
      username      : process.env.VUE_APP_ANONYMOUS_USERNAME,
      password      : process.env.VUE_APP_ANONYMOUS_PASSWORD,
    }
    //const res = await client.post('/api/auth/loginClientsSakura', payload);
    const res = {
      "data": {
          "Authorization": {
              "access_token": "HspWhNL1v2gSOKISPoCh7WaIzt0ZFI",
              "client_id": "5b7766bdfad78c303462937dda1d6d0f",
              "expires_in": 1661640000.0,
              "pass_firebase": "anonymous#S3rv1X016",
              "token_type": "Bearer"
          },
          "InfoUser": {
              "address": "7",
              "address_list": [],
              "allergies": null,
              "birthdate": "0000-00-00",
              "blood_donor": null,
              "blood_type_name": "A+",
              "cellphone": null,
              "city": null,
              "city_id": null,
              "client_secret": "a89k2M0A5Y44",
              "confirmation_code": "LiJ6ozRy2BtLYA3",
              "confirmed": 1,
              "created_at": "Thu, 28 Jul 2022 23:01:59 GMT",
              "date": null,
              "department": null,
              "department_id": null,
              "email": "usuariowebtestdev@gmail.com",
              "email_optional": null,
              "eps_id": 21,
              "gender_name": "MASCULINO",
              "height": "None",
              "id": 27634,
              "id_blood_type": 1,
              "id_gender": 1,
              "id_type_affiliate": null,
              "id_type_nit": 3,
              "id_user": 27634,
              "identification": null,
              "lastname": "TEST DEV",
              "name": "WEB",
              "names": "WEB",
              "neighborhood": null,
              "nit": "nvkK131013",
              "observation": null,
              "phone": null,
              "preexistences": null,
              "type_event": [],
              "type_nit_name": "CC",
              "type_user": 2,
              "updated_at": "Thu, 28 Jul 2022 23:01:59 GMT",
              "url_image": "https://storage.googleapis.com/sitidoctor-161813.appspot.com/images/silueta.jpg",
              "weight": "None"
          }
      },
      "message": "success!",
      "status": 200
    }
    if( !res ) return;
    const data          = res['data'];
    const authorization = data['Authorization'];
    const token         = authorization['access_token'];

    sessionStorage.setItem('temporary_token', token);
  },

  async reset_password(_, email: string): Promise<any> {
    const p = {
      email: email,
      url: `https://sakura.foodchains.com.br/auth/reset-password?email=${email}`
    }
    const res = await marketplace_client.post('/api/userPassword', p);
    if( !res ) return null;
    return {};
  },

  async change_password(_, payload): Promise<any> {
    const p = {
      "email": payload.email,
      "password": payload.pass
    }
    const res = await marketplace_client.put(`/api/userPassword/${payload.token}`, p);
    if( !res ) return null;
    return {};
  },

  async attemp_logout({ dispatch }, redirect?: string): Promise<void> {
    dispatch('logout_firebase');
    dispatch('logout_sitidoctor');
    const current_location = window.location.href;
    const path = current_location.split('/');
    const auth_path = path.find(item => item === 'auth');
    if (auth_path) return;
    setTimeout(() => {
      window.location.href = `/auth/login?redirect=${ redirect ? redirect : '' }`;
    }, 2500);
  },

  async logout_firebase(): Promise<void> {
    const auth = getAuth();
    auth.signOut();
  },

  async logout_sitidoctor({ commit }): Promise<void> {
    await client.post('/api/auth/logout');
    commit(AuthMutations.REMOVE_SESSION);
  },

  async get_user({ commit, state }): Promise<any> {
    const user = state.session.user;
    const res  = await client.get(`api/v1/UserPerfil/${ user.id }`);
    if( !res ) return null;
    commit(AuthMutations.SET_USER, res.data);
    return res;
  },

  async update_user_profile({ dispatch }, payload: any) {
    const res = await siti_client.put(`/api/v1/patient/${ payload.id }`, payload);
    if( !res ) return null;
    await dispatch('get_user');
    return res;
  },

}
