/*=========================================================================================
  Item Name: products/state
  Item Description: state of products
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

import { ProductsI } from "../interfaces/products.interface";

export const state: ProductsI = {
  promotions: [],
  products : [],
}
