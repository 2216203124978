export default {
  path : 'lojas',
  name : 'shops',
  component: () => import('../views/shops/index.vue'),
  meta: {
    requiresAuth: false,
    title: 'Lojas',
  },
  children: [
    {
      path: ':id',
      name: 'shop',
      component: () => import('../views/shops/shop.vue'),
      children: [
        {
          path: '',
          name: 'shop-home',
          component: () => import('../views/shops/shop-home.vue'),
          meta: {
            requiresAuth: false,
            title: 'Loja',
          },
        },
        {
          path: 'categorias/:category',
          name: 'shop-categories',
          component: () => import('../views/shops/shop-categorie.vue'),
          meta: {
            requiresAuth: false,
            title: 'categoria de loja'
          }
        }
      ]
    }
  ]
};
