import { Module } from "vuex";
import { CartI } from "../interfaces/cart.interface";
// interfaces
import { RootStateI } from "../interfaces/types.interface";
// store moudules
import { actions }   from "./actions";
import { getters }   from "./getters";
import { mutations } from "./mutations";
import { state }     from "./state";

export const cart: Module<CartI, RootStateI> = {
  actions,
  getters,
  mutations,
  state,
  namespaced: true,
}