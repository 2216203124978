import { TrackingI } from "../interfaces/tracking.interface";

export const state: TrackingI = {
  orders: [],
  order: {
    name                : '',
    user_id             : 0,
    lastname            : '',
    user_identification : '',
    updated_at          : '',
    user_uid            : '',
    created_at          : '',
    email               : '',
    tracking_finished   : [],
    tracking_progress   : []

  },
  statuses: [],
  order_details: {
    order_id: ''
  }
}
