/*=========================================================================================
  Item Name: products/state
  Item Description: state of products
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

import { SearchI } from "../interfaces/search.interface";

export const state: SearchI = {
}
