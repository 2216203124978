/*=========================================================================================
  Item Name: products/mutations
  Item Description: mutations of products
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

import { MutationTree } from "vuex";

import { BannersI } from "../interfaces/banners.interface";

export enum PromotionsMutations {
}

export const mutations: MutationTree<BannersI> = {
}
