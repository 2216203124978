import { AuthI } from "../interfaces/auth.interface";

export const state: AuthI = {
  session: {
    authorization: {
      access_token: '',
      client_id: '',
      expires_in: 0,
      token_type: '',
    },
    user: {
      address           : '',
      birthdate         : '',
      blood_donor       : false,
      blood_type_name   : '',
      cellphone         : '',
      city              : '',
      city_id           : '',
      confirmation_code : '',
      confirmed         : false,
      email             : '',
      eps_id            : '',
      height            : 0,
      id                : 0,
      id_blood_type     : 0,
      id_gender         : 0,
      id_type_affiliate : 0,
      id_type_nit       : 0,
      lastname          : '',
      name              : '',
      names             : '',
      neighborhood      : '',
      nit               : '',
      observation       : '',
      phone             : '',
      type_user         : 0,
      url_image         : '',
      weight            : 0,
      address_list      : [],
    }
  }
}
