import Vue     from 'vue';
import Router  from 'vue-router';
import Cookies from 'js-cookie';
import { getAuth } from "firebase/auth";

import auth_routes       from './auth-routes';
import services_routes   from './services-routes';
import profile_routes    from './profile-routes';
import categories_routes from './categories-routes';
import shops_routes      from './shops-routes';
import cart_routes       from './cart-routes';
import payment_routes    from './payment-routes';
import result_routes     from './results-routes';
import products_routes   from './products-routes';
import shopping_routes   from './shopping-routes';
import promotions_routes from './promotions-routes';

Vue.use(Router);

const router: Router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: () => import('../layouts/main/main.vue'),
      children: [
        // Auth routes
        auth_routes,
        {
          path : '',
          name : 'home',
          component: () => import('../views/home/index.vue'),
        },
        // medical services
        /* {
          path : 'agendar-cita-particular',
          name : 'agendar-cita-particular',
          redirect : '/perfil/ingresar-nit',
        },
        {
          path : 'triage',
          name : 'triage',
          redirect : '/perfil/ingresar-datos-medicos',
        }, */
        // profile routes
        profile_routes,
        // services routes
        services_routes,
        // categories routes
        categories_routes,
        // shops routes
        shops_routes,
        // cart routes
        cart_routes,
        // payment routes
        payment_routes,
        // result routes
        result_routes,
        // products routes
        products_routes,
        // shopping routes
        shopping_routes,
        // promotions routes
        promotions_routes,
        // legal
        {
          path : 'termos-e-condicoes',
          name : 'terms-and-conditions',
          component: () => import('../views/legal/terms-conditions.vue'),
          meta: {
            title: 'Termos e Condições',
          }
        },
        {
          path: 'politica-de-processamento-de-dados',
          name: 'privacy-policy',
          component: () => import('../views/legal/privacy-policy.vue'),
          meta: {
            title: 'Política de processamento de dados'
          }
        }
      ]
    },
    // full page layouts
    {
      path: '',
      component: () => import('../layouts/full-page/full-page.vue'),
      children: [
      ],

    },
    // Not found fallback
    {
      path: '*',
      name: 'not-found',
      //component: () => import('../views/home/index.vue'),
      component: () => import('../views/not-found/index.vue'),
    },
  ],
});

router.afterEach(() => {
  const loadingEl = document.getElementById('loading');
  if(loadingEl) loadingEl.remove();
});

router.beforeEach(async (to: any, from, next) => {
  const defaultTitle = 'Marketplace';
  const title = to.meta.title ? `${ to.meta.title } - ${defaultTitle}` : defaultTitle;
  document.title = title;
  // scroll to top
  window.scrollTo(0, 0);

  if (to.name === 'home') {
    const redirect = to.query.redirect;
    const query = to.query;
    delete query.redirect;
    
    if (redirect) {
      next({ path : redirect, query });
      return;
    }
  }
  
  if (to.meta.requiresAuth) {
    const is_persistent = Cookies.get('token');
    const token = is_persistent ? Cookies.get('token') : sessionStorage.getItem('token');

    if (!token) {
      next({path: '/auth/login', query: { redirect: to.path, ...to.query }});
      return;
    } else {
      getAuth().onAuthStateChanged(user => {
        if (!user) {
          next({path: '/auth/login', query: { redirect: to.path, ...to.query }});
          return;
        }
        return null;
      });
      next();
      return;
    }
  }

  next();
});

export { router };
