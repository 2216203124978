import { MutationTree } from "vuex";
import {
  RootObject,
  TrackingFinishedI,
  TrackingI,
  TrackingProgressI
} from "../interfaces/tracking.interface";

export enum TrackingMutations {
  SET_ORDER          = "SET_ORDER",
  SET_ORDER_STATUSES = "SET_ORDER_STATUSES",
  SET_ORDER_DETAILS  = "SET_ORDER_DETAILS",
}

export const mutations: MutationTree<TrackingI> = {
  [TrackingMutations.SET_ORDER] (state, order) {
    const tracking_progress = order.tracking_progress;
    const tracking_finished = order.tracking_finished;

    const map_order_progress: TrackingProgressI[] = [];
    const map_order_finished: TrackingFinishedI[] = [];

    for (const order of tracking_progress) {
      map_order_progress.push({
        ...order,
        collapsed: true,
      });
    }

    for (const order of tracking_finished) {
      map_order_finished.push({
        ...order,
        collapsed: true,
      });
    }

    order.tracking_progress = map_order_progress;
    order.tracking_finished = map_order_finished;
    state.order = order;
  },
  [TrackingMutations.SET_ORDER_STATUSES] (state, statuses: any) {
    state.statuses = statuses;
  },
  [TrackingMutations.SET_ORDER_DETAILS] (state, order) {
    state.order_details = order;
  }
}
