/*=========================================================================================
  Item Name: products/state
  Item Description: state of products
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

import { BannersI } from "../interfaces/banners.interface";

export const state: BannersI = {
  banners: [],
}
