import { ActionTree } from "vuex";
// interfaces
import { RootStateI } from "../interfaces/types.interface";
// mutations

// http client
import { siti_client as client, marketplace_client, siti_client } from '@/http';
import { AgreementDataI, AgreementI } from "../interfaces/agreements.interface";

export const actions: ActionTree<AgreementI, RootStateI> = {
  async get_agreements (): Promise<AgreementDataI[]> {
    const res = await siti_client.get('/api/v1/agreements');
    if (!res) return [];
    return res.data;
  }
}
