import { MutationTree } from "vuex";
import Cookies          from "js-cookie";
// interfaces
import { LocationI } from "../interfaces/location.interface";

export enum LocationMutations {
  SET_CITIES       = 'SET_CITIES',
  SET_DEPARTMENTS  = 'SET_DEPARTMENTS',
  SET_ADDRESS      = 'SET_ADDRESS',
  SET_ADDRESS_LIST = 'SET_ADDRESS_LIST',
}

export const mutations: MutationTree<LocationI> = {
  [LocationMutations.SET_CITIES](state, cities: any[]) {
    state.cities = cities;
  },
  [LocationMutations.SET_DEPARTMENTS](state, departments: any[]) {
    state.departments = departments;
  },
  [LocationMutations.SET_ADDRESS](state, address: any) {
    state.address = address;
  },
  [LocationMutations.SET_ADDRESS_LIST](state, address_list: any[]) {
    state.address_list = address_list;
  }
}
