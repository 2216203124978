
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'App',
  components: {
  },
})
export default class App extends Vue {
}
