export default {
  path: 'promo',
  name: 'promociones',
  component: () => import('@/views/promotions/index.vue'),
  meta: {
    title: 'Promoções',
    requiresAuth: false,
  },
  children: [
    {
      path: ':id',
      name: 'promo-detail',
      component: () => import('@/views/promotions/promotion.vue'),
      meta: {
        title: 'Detalhe da promoção',
        requiresAuth: false,
      },
    }
  ]
}
