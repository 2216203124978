export default {
  path: 'servicios',
  name: 'services',
  component: () => import('../views/services/index.vue'),
  children: [
    /* {
      path: '',
      redirect: '/servicios/citas',
    },
    {
      path: 'citas',
      name: 'citas',
      component: () => import('../views/services/appointments/index.vue'),
      meta: {
        requiresAuth: true,
      },
      children : [
        // redirect to search
        {
          path: '',
          redirect: '/servicios/citas/buscar-cita-particular',
        },
        // search for private appointments
        {
          path : 'buscar-cita-particular',
          name : 'search-private-appointment',
          component: () => import('../views/services/appointments/search-private-appointment.vue'),
          meta: {
            title : 'Agendar cita particular',
          },
        },
        // search for tele-orientation appointments
        {
          path : 'buscar-cita-teleorientacion',
          name : 'search-teleorientation-appointment',
          component: () => import('../views/services/appointments/search-teleorientation-appointment.vue'),
          meta: {
            title : 'Agendar cita por teleorientación prioritaria',
          },
        },
        // results
        {
          path : 'resultados',
          name : 'appointment-results',
          component: () => import('../views/services/appointments/appointment-results.vue'),
          meta : { title: 'Resultados de búsqueda' }
        },
        // schedule appointment
        {
          path : 'agendar/:id',
          name : 'schedule-appointment',
          component: () => import('../views/services/appointments/schedule-appointment.vue'),
          meta: {
            title : 'Agendar cita',
            requiresAuth: true,
          }
        },
        // pay appointment
        {
          path : 'pagar/:id',
          name : 'pay-appointment',
          component: () => import('../views/services/appointments/pay-appointment.vue'),
          meta: {
            title : 'Pagar cita',
            requiresAuth: true,
          }
        },
        // payment confirmation
        {
          path : 'confirmação/:id',
          name : 'payment-confirmation',
          component: () => import('../views/services/appointments/payment-confirmation.vue'),
          meta: {
            title : 'Confirmación de pago',
            requiresAuth: true,
          }
        }
      ]
    },
    // pre-diagnostic
    {
      path : 'prediagnostico',
      name : 'prediagnostic',
      component: () => import('../views/services/prediagnostic/index.vue'),
      meta: {
        title : 'Pre-diagnóstico virtual gratis',
      }
    },
    // agreements
    {
      path : 'convenios',
      name : 'agreements',
      component: () => import('../views/services/agreements/index.vue'),
      meta: {
        title : 'Convenios',
      },
      children : [
        {
          path : '',
          name: 'agreements-list',
          component: () => import('../views/services/agreements/agreements.vue'),
          meta: {
            title : 'Convenios',
          },
        },
        {
          path : ':id',
          name : 'agreement-specialities',
          component: () => import('../views/services/agreements/agreement-specialities.vue'),
          meta: {
            title : 'Especialidades',
          }
        }
      ]
    } */
  ]
}
