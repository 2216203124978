export default {
  path : 'perfil',
  name : 'perfil',
  component: () => import('../views/profile/index.vue'),
  children: [
    // main
    {
      path: '',
      name: 'profile-home',
      component: () => import('../views/profile/profile.vue'),
      meta : {
        requiresAuth : true,
        title : 'Perfil',
      }
    },
    // address list
    /* {
      path: 'direcciones',
      name: 'profile-address-list',
      component: () => import('../views/profile/address-list.vue'),
      meta : {
        requiresAuth : true,
        title : 'Direcciones',
      }
    }, */
    // payment methods
    /* {
      path: 'metodos-de-pago',
      name: 'payment-methods',
      component: () => import('../views/profile/payment-methods.vue'),
      meta : {
        requiresAuth : true,
        title : 'Métodos de pago',
      }
    }, */
    // shopping history
    {
      path: 'compras',
      name: 'shopping-history',
      component: () => import('../views/profile/shopping-history.vue'),
      meta : {
        requiresAuth : true,
        title : 'Minhas compras',
      }
    }
  ]
};