import { MutationTree } from "vuex";
import { CartI } from "../interfaces/cart.interface";

export enum CartMutations {
  SET_CART_ITEMS = "SET_CART_ITEMS",
  SET_CART_ORDER_ITEMS = "SET_CART_ORDER_ITEMS",
  SET_PRODUCTS_BONUSES = "SET_PRODUCTS_BONUSES",
  SET_ORDER_ITEMS = "SET_ORDER_ITEMS",
}

export const mutations: MutationTree<CartI> = {
  [CartMutations.SET_CART_ITEMS](state, payload: number) {
    state.cart_items = payload;
  },
  [CartMutations.SET_PRODUCTS_BONUSES](state, payload: any) {
    state.products_bonuses = payload;
  },
  [CartMutations.SET_ORDER_ITEMS](state, payload: any) {
    state.order_items = payload;
  },
  [CartMutations.SET_CART_ORDER_ITEMS](state, payload: any) {
    state.cart_order_items = payload;
  },
};
