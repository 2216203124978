import axios, { AxiosError } from "axios";
import Cookies from "js-cookie";

const auth_paths = ["/auth/login/email"];

const marketplace_client = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT_MARKETPLACE,
  timeout: 180000,
});

const marketplace_client_login = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT_MARKETPLACE,
  timeout: 180000,
});

const marketplace_client_order = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT_MARKETPLACE,
  timeout: 180000
});

const marketplace_client_payment = axios.create({
  baseURL: process.env.VUE_APP_BASE_PAYMENT,
  timeout: 180000
});

const is_persistent = Cookies.get('token');

const get_auth_token = () => {
  const temporary_token = sessionStorage.getItem("temporary_token");
  if (temporary_token) return `Bearer ${temporary_token}`;

  if (is_persistent) return `Bearer ${Cookies.get("token")} `;
  else return `Bearer ${sessionStorage.getItem("token")}`;
};

const auth_Interceptor = (config: any) => {
  config.headers.Authorization = get_auth_token();
  config.headers.translate = localStorage.getItem("translate");
  return config;
};

const response_interceptor = (response: any) => {
  return response.data;
};

const error_interceptor = (error: AxiosError) => {
  if (!error.response) return null;

  switch (error.response.status) {
    case 401: {
      // current path
      const path = window.location.pathname;
      const finded = auth_paths.find((p: string) => p === path);

      if (!finded) {
        Cookies.remove("token");
        Cookies.remove("user");
        window.location.href = "/auth/login";
      }

      break;
    }

    case 403: {
      window.location.href = "/403";
      break;
    }

    default: {
      console.log(error.message);
      const error_message = error.response.data.message;
      if (error_message) sessionStorage.setItem("error_message", error_message);
      break;
    }
  }

  return null;
};

const error_interceptor_login = (error: AxiosError) => {
  if (!error.response) return null;

  switch (error.response.status) {
    case 401: {
      // current path
      const path = window.location.pathname;
      const finded = auth_paths.find((p: string) => p === path);

      if (!finded) {
        Cookies.remove("token");
        Cookies.remove("user");
        window.location.href = "/auth/login";
      }

      break;
    }

    case 403: {
      window.location.href = "/403";
      break;
    }

    default: {
      console.log(error.message);
      const error_message: any = error.response;
      if (error_message) sessionStorage.setItem("error_message", error_message);
      break;
    }
  }

  return error.response;
};

const error_interceptor_order = (error: AxiosError) => {
  if( !error.response ) return null;

  switch(error.response.status) {

    case 401: {
      // current path
      const path = window.location.pathname;
      const finded = auth_paths.find((p: string) => p === path);

      if (!finded) {
        Cookies.remove('token');
        Cookies.remove('user');
        window.location.href = '/auth/login';
      }
      
      break;
    }

    case 403: {
      window.location.href = '/403';
      break;
    }
    
    default: {
      console.log(error.message);
      const error_message: any = error.response.data.message;
      if( error_message ) sessionStorage.setItem('error_message', error_message);
      break;
    }

  }
  
  return error.response;
}

marketplace_client.interceptors.request.use(auth_Interceptor);
marketplace_client.interceptors.response.use(
  response_interceptor,
  error_interceptor
);

marketplace_client_login.interceptors.request.use(auth_Interceptor);
marketplace_client_login.interceptors.response.use(
  response_interceptor,
  error_interceptor_login
);

marketplace_client_order.interceptors.request.use(auth_Interceptor);
marketplace_client_order.interceptors.response.use(response_interceptor, error_interceptor_order);

marketplace_client_payment.interceptors.request.use(auth_Interceptor);
marketplace_client_payment.interceptors.response.use(response_interceptor, error_interceptor_order);

export { marketplace_client, marketplace_client_login, marketplace_client_order, marketplace_client_payment };
