import { ActionTree } from "vuex";
import { TrackingI } from "../interfaces/tracking.interface";
import { RootStateI } from "../interfaces/types.interface";

import Cookies from "js-cookie";
import { collection, doc, getDoc, getDocs, query, where, onSnapshot } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { TrackingMutations } from "./mutations";
import { marketplace_client } from "@/http";

const db = getFirestore();
const collection_name = 'serviroutes_tracking_mobile';

export const actions: ActionTree<TrackingI, RootStateI> = {

  async get_all_orders({ commit }) {
    try {
      const user = JSON.parse(Cookies.get('user') || '{}');
      const nit = user.nit;
      
      if (!nit) throw new Error('No tiene una identificación');
      
      const doc_ref = doc(db, collection_name, nit);
      const doc_snap = await getDoc(doc_ref);
      const data = doc_snap.data();
      
      if(data){
        data.tracking_progress = data.tracking_progress.reverse();
        data.tracking_finished = data.tracking_finished.reverse();
        commit(TrackingMutations.SET_ORDER, data);
      }
      return data;

    } catch (error) {
      console.log(error);
      return;
    }
  },

  async get_all_status({commit}) {
    const res = await marketplace_client.get('/api/status');
    if (!res) return;
    commit(TrackingMutations.SET_ORDER_STATUSES, res.data);
    return res;
  },

  async get_order ({commit}, order_id: string) {
    const user = JSON.parse( Cookies.get('user') );    
    const tracking_ref = collection(db, 'serviroutes_tracking');
    const q = query(
      tracking_ref,
      where('order_id', '==', order_id),
      where('user.identification', '==', user.nit)
    );

    let data: any[] = [];
    
    // const q_snapshot = await getDocs(q);
    onSnapshot(q, (snap) => {
      data = snap.docs.map(d => { return { id: d.id, ...d.data() } });
      commit(TrackingMutations.SET_ORDER_DETAILS, data[0]);
    });
    
  }
}
