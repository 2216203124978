import { LocationI } from "../interfaces/location.interface";

export const state: LocationI = {
  cities      : [],
  departments : [],
  address      : {
    id              : 0,
    department_dane : '11',
    city_dane       : '11001',
    address         : '',
    neighborhood    : '',
    phone           : '',
    cellphone       : '',
    email           : '',
    data            : '',
    cx              : 0,
    cy              : 0,
  },
  address_list : [],
}
