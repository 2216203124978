import { ActionTree } from "vuex";
// interfaces
import { RootStateI } from "../interfaces/types.interface";
import { PaymentsI } from "../interfaces/payment.interfase";
import Cookies from 'js-cookie';
import { siti_client } from "@/http/siti-client";
import { marketplace_client } from "@/http/marketplace-client";
import { CouponsI } from "../interfaces/coupons.interface";


export const actions: ActionTree<CouponsI, RootStateI> = {
  
  async get_coupons_list (): Promise<any> {
    const res = await marketplace_client.get('/api/promotions');
    if (!res) return;
    return res.data;
  },

  async activate_coupon (_, payload: any): Promise<any> {
    const res = await marketplace_client.post('/api/orders_promotion', payload);
    if (!res) return;
    return res.data;
  },

  async delete_coupon (_, payload: number): Promise<any> {
    const res = await marketplace_client.delete('/api/orders_promotion/'+ String(payload['order_id']) );
    if (!res) return;
    return res.data;
  },

}
