import axios, { AxiosError } from 'axios';
import Cookies from 'js-cookie';

const auth_paths = ['/auth/login/email'];

const siti_client = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT_SITIDOCTOR,
  timeout: 180000,
});

const is_persistent = Cookies.get('token');

const get_auth_token = () =>  {
  const temporary_token = sessionStorage.getItem('temporary_token');
  if( temporary_token ) return `Bearer ${ temporary_token }`;

  if( is_persistent )
    return `Bearer ${ Cookies.get('token') } `;
  else
    return `Bearer ${ sessionStorage.getItem('token') }`;	
    
}

const auth_Interceptor = (config: any) => {
  config.headers.Authorization = get_auth_token();
  return config;
};

const response_interceptor = (response: any) => {
  return response.data;
}

const error_interceptor = (error: AxiosError) => {
  if( !error.response ) return null;

  switch(error.response.status) {

    case 401: {
      // current path
      const path = window.location.pathname;
      const finded = auth_paths.find((p: string) => p === path);

      if (!finded) {
        Cookies.remove('token');
        Cookies.remove('user');
        window.location.href = '/auth/login';
      }
      
      break;
    }

    case 403: {
      window.location.href = '/403';
      break;
    }
    
    default: {
      console.log(error.message);
      const error_message = error.response.data.message;
      if( error_message ) sessionStorage.setItem('error_message', error_message);
      break;
    }

  }

  return null;
}

siti_client.interceptors.request.use(auth_Interceptor);
siti_client.interceptors.response.use(response_interceptor, error_interceptor);

export { siti_client };
