import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
// interfaces
import { RootStateI } from "./interfaces/types.interface";
// local app config
import { actions }   from "./actions";
import { getters }   from "./getters";
import { mutations } from "./mutations";
import { state }     from "./state";
// modules
import { products }     from "./products";
import { shops }        from "./shops";
import { categories }   from "./categories";
import { users }        from "./users";
import { auth }         from "./auth";
import { location }     from "./location";
import { payments }     from "./payments";
import { appointments } from "./appointments";
import { tracking }     from "./tracking";
import { cart }         from "./cart";
import { agreements }   from "./agreements";
import { coupons }      from "./coupons";
import { banners }      from "./banners";
import { search }       from "./search";

Vue.use(Vuex);

const store : StoreOptions<RootStateI> = {
  actions,
  getters,
  mutations,
  state,
  modules : {
    products,
    shops,
    categories,
    users,
    auth,
    location,
    payments,
    appointments,
    tracking,
    cart,
    agreements,
    coupons,
    banners,
    search
  }
};

export default new Vuex.Store<RootStateI>(store);
