import { Module } from "vuex";
// interfaces
import { PaymentsI } from "../interfaces/payment.interfase";
import { RootStateI } from "../interfaces/types.interface";
// store moudules
import { actions }   from "./actions";
import { getters }   from "./getters";
import { mutations } from "./mutations";
import { state }     from "./state";

export const payments: Module<PaymentsI, RootStateI> = {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}