/*=========================================================================================
  Item Name: products/actions
  Item Description: actions of products
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

import { ActionTree } from "vuex";

import { RootStateI } from "../interfaces/types.interface";
import { BannersI } from "../interfaces/banners.interface";

import { siti_client as client, marketplace_client } from "@/http";

export const actions: ActionTree<BannersI, RootStateI> = {
  async banner_category(_, payload): Promise<any> {
    const res = await marketplace_client.post("/api/banner_category", payload);
    if (!res) return;
    return res.data;
  },
  async banner_promotions(): Promise<any> {
    const res = await marketplace_client.get("api/BannerPromotions");
    if (!res) return;
    return res.data;
  },

  async redirect(_, payload): Promise<any> {
    const res = await marketplace_client.post("/api/banner_filter", payload);
    if (!res) return;
    return res.data;
  },
};
