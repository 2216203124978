import Vue from 'vue';
import App from './App.vue';
// Vuew router
import { router } from './router';

// impport app styles
import './tailwind.css';
import './assets/css/app.css';
import vuetify from './plugins/vuetify';

// firebase
import './firebase';

// vuex store
import store from './store';

// filters
import './filters';

// tour
import VueTour from 'vue-tour';

// I18n
import VueI18n from 'vue-i18n'
import i18n from '@/plugins/i18n'

require('vue-tour/dist/vue-tour.css');


//sweetalert
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);
Vue.use(VueI18n)
Vue.config.productionTip = false

new Vue({
  vuetify,
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
