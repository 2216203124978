export default {
  path: 'auth',
  component: () => import('../views/auth/index.vue'),
  children: [
    {
      path: '',
      name: 'auth',
      component: () => import('../views/auth/auth.vue'),
    },
    // login
    {
      path: 'login',
      name: 'login',
      component: () => import('../views/auth/login/email.vue')
      /* children : [
        {
          path: '',
          name: 'login-index',
          component: () => import('../views/auth/login/login.vue'),
        },
        {
          path: 'phone',
          name: 'login-phone',
          component: () => import('../views/auth/login/phone.vue'),
        },
        {
          path: 'email',
          name: 'login-email',
          component: () => import('../views/auth/login/email.vue'),
        }
      ] */
    },
    // register
    /* {
      path: 'register',
      name: 'register',
      component: () => import('../views/auth/register/index.vue'),
      children : [
        {
          path: '',
          name: 'register-index',
          component: () => import('../views/auth/register/register.vue'),
        },
        {
          path: 'phone',
          name: 'register-phone',
          component: () => import('../views/auth/register/phone.vue'),
        },
        {
          path: 'email',
          name: 'register-email',
          component: () => import('../views/auth/register/email.vue'),
        },
      ]
    }, */
    /* {
      path: 'register-info',
      name: 'register-info',
      component: () => import('../views/auth/register/register-info.vue'),
    },
    {
      path: 'verify-phone-code',
      name: 'verify-phone-code',
      component: () => import('../views/auth/verify-phone-code.vue'),
    }, */
    // reset password
    {
      path: 'reset-password',
      name: 'reset-password',
      component: () => import('../views/auth/reset-password.vue'),
    },
  ]
}
